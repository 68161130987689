import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const FaqsAndContacts = ({ data = {} }) => {

  const { modules, title, description = false, id } = mapPageData(data.page)


  const modulesToRender = useModules(modules)

  // const { submitted, setSubmitted } = React.useState(submitted)

  return (
      <Layout
        staticMenuLayout={true}
        homeLink={true}
        headerClassName={'  '}
        noHero={true}
        heroModule={[]}
        className=" static-page faqs-and-contacts "
      >
      <SEO title={title} description={description} />
        {/* Content goes here */}
        { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}



        {/* Uneditable form */}

        <section className="px-4 py-12 bg-cta md:px-12 lg:px-16 lg:py-16">
          <h3 className="mb-0 text-3xl font-bold leading-tight">Can't find what you're looking for?</h3>
          <p className="text-xl ">Fill out the form here to get in touch with our HelpDesk</p>
          <form className="mt-8 space-y-4" name="contact" method="POST" data-netlify="true" >
          <input type="hidden" name="form-name" value="contact" />
            <div>
              <label for="name" className="block h-0 text-xs opacity-0 ">
                Your Name
              </label>
              <input id="name" type="text" required className="w-full py-1 pl-2 md:w-64 lg:w-1/3 placeholder-knorr" placeholder="Name" />
            </div>
            <div>
              <label for="email" className="block h-0 text-xs opacity-0 ">
                Your Email
              </label>
              <input id="email" type="email" required className="w-full py-1 pl-2 md:w-64 lg:w-1/3 placeholder-knorr" placeholder="Email" />
            </div>
            <div>
              <label for="message" className="block h-0 text-xs opacity-0 ">
                Your Message
              </label>
              <textarea rows="3" required id="message" className="w-full px-2 py-1 placeholder-knorr" placeholder="Ask your question here..." />
            </div>
            <p className="hidden">
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
            </p>
            <button type="submit" className="px-3 py-2 text-white bg-knorr">SEND TO HELPDESK</button>
          </form>
        </section>
        <PageNav 
        prev={{  text: "Download Assets", linkTo: "/downloadassets"}} 
      />
      
      </Layout>
  )
}

export default FaqsAndContacts


export const FaqsAndContactsQuery = graphql`
  query FaqsAndContactsQuery {
  page: contentfulPage(pageId: {eq: "contact-faqs"}) {
      ...PageFragment
    }
  }
`
